import * as React from 'react'
import BGImage from "../images/relaxing.jpg"

function SimplePhotoAndText(){
    return(
        <div className={"dark:bg-gray-900 dark:text-white"}>
        <div className={"flex flex-row flex-wrap justify-center max-w-5xl mx-auto pb-36"}>
            <div className={"lg:w-1/2"}>
                <h1 className={"text-3xl text-center text-malibu-500 pt-5 pb-10"}>Additional Services</h1>
                <p className={"text-center pt-5 font-bold"}>Clean-up After Parties</p>
                <p className={"p-5 leading-loose"}>Bathrooms, kitchen vacuum and mop floors. trash pickup. Clean tables, chairs washing the floors outside, etc.</p>
                <p className={"text-center pt-5 font-bold"}>Bed Making</p>
                <p className={"p-5 leading-loose"}>Full bedmaking & turndown service, expect nothing less than perfection when you get into bed</p>
                <p className={"text-center pt-5 font-bold"}>Oven Cleaning</p>
                <p className={"p-5 leading-loose"}>Get your oven back to it’s cleanest and best shape with a full cleaning</p>
                <p className={"p-2 text-gray-500 text-center pb-10 lg:pb-0"}>Grout cleaning additional fee -
                Window cleaning additional fee</p>
            </div>
            <img className={"lg:w-1/2"} src={BGImage} alt={"palceholder"}/>
        </div>
        </div>
    )
}

export default SimplePhotoAndText