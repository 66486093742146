import * as React from 'react'
import CellSVG from "../images/mobile-screen-button-solid.svg"
import HoursSVG from "../images/clock-solid.svg"
import LocationSVG from "../images/map-location-dot-solid.svg"

function GlanceCard(props){
    return(
        <div className={"bg-malibu-500 dark:bg-malibu-900 max-w-sm sm:max-w-xs w-[calc(100%-2rem)] h-36 m-2 p-2 rounded text-white flex flex-row"} onClick={props.onClick}>
            <div className={"w-1/3 h-full flex justify-center items-center"}>
                <img className={"filter invert h-1/3"} alt={props.alt} src={props.src}/>
            </div>
            <div className={"w-2/3 flex flex-col justify-center items-center"}>
                <h2 className={"text-xl text-center font-medium"}>{props.title}</h2>
                {props.children}
            </div>

        </div>
    )
}

function GlanceInfo() {
    function callNumber() {
        window.location.href  = "tel:8587769914"
    }

    return(
        <div className={"flex flex-col sm:flex-row justify-center items-center dark:bg-gray-900 dark:text-white transition duration-500"}>

            <GlanceCard title={"Call Now"} alt={"Cell phone icon"} src={CellSVG} onClick={callNumber}>
                <p className={"text-center text-2xl font-bold cursor-pointer text-white"}>(858) 776-9914</p>
            </GlanceCard>

            <GlanceCard title={"Hours"} alt={"Clock icon"} src={HoursSVG}>
                <div className={"flex flex-col"}>
                    <div className={"p-2"}>
                        <p>Mon-Fri - 8am-6pm</p>
                        <p className={"text-center"}>Sat - by appt.</p>
                        <p className={"text-center"}>Sun - Closed</p>
                    </div>
                </div>
            </GlanceCard>

            <GlanceCard title={"Areas We Service"} alt={"Location icon"} src={LocationSVG}>
                <p className={"text-center"}>Rancho Bernardo, Poway
                    and 4s Ranch Area</p>
            </GlanceCard>

        </div>
    )
}

export default GlanceInfo