import * as React from 'react'
import Headshot from "../images/headshot.jpg"

function PersonSpotlight(){
    return(
        <div className={"flex flex-col align-middle items-center pt-10 dark:bg-gray-900 dark:text-white transition duration-500"}>
            <img className={"rounded-full h-128"} src={Headshot} alt={"Jason Latoe headshot"}/>
            <h1 className={"text-4xl font-bold mt-4"}>
                Jason Latoe
            </h1>
            <p className={"text-gray-500"}>Owner/Operator</p>
            <p className={"max-w-lg leading-loose p-4"}>
                Jason Latoe has been painting professionally both in residential and commerical projects for over 15 years. He can tackle any painting project put in front of him and always give each job his undivided attention and focus. Expect nothing less than the best when you hire Jason to help build your house into a home.
            </p>
        </div>
    )
}

export default PersonSpotlight