import * as React from 'react'
import Nav from "../components/nav";
import Footer from "../components/footer";
import BackgroundImage from "../components/background-image";
import BGImage from "../images/about-page-image.jpg"
import BGImageM from "../images/forest-m.jpg"
import GlanceInfo from "../components/glance-info";
import PersonSpotlight from "../components/person-spotlight";
import Services from "../components/services";
import CtaBar from "../components/cta-bar";
import SimplePhotoAndText from "../components/simple-photo-and-text";
import MoreBusinessInfo from "../components/more-business-info";
import CtaBarAlternate from "../components/Cta-bar-alternate";
import {Helmet} from "react-helmet";

const AboutPage = () => {
    return(
        <main>
            <Helmet>
                <title>Rancho Bernardo Maids - About Us</title>
                <meta name="description" content="Rancho Bernardo Maids offers the best cleaning services, deep cleans, & move outs for houses, apartments, condos & more" />
                <meta name="keywords" content="Deep Cleaning, Standard House Cleaning, Move Outs, Party Clean Ups"/>
                <meta name="robots" content="index, follow" />
            </Helmet>
            <Nav/>
            <BackgroundImage image={BGImage} mobileImage={BGImage} alt={"forest background"} height={"h-128"} backgroundHeight={"h-152"} hero={true}>
                <h1 className={"text-8xl text-center text-white p-6"}>About Us</h1>
            </BackgroundImage>
            <GlanceInfo/>
            <MoreBusinessInfo/>

            <Services/>

            <SimplePhotoAndText/>
            <CtaBarAlternate/>
            <Footer/>
        </main>
    )
}

export default AboutPage

