import * as React from 'react'

function MoreBusinessInfo(){
    return(
        <div className={"pt-24 dark:bg-gray-900 dark:text-white"}>
            <h1 className={"text-center text-4xl text-malibu-500 font-bold pb-10"}>Who We Are</h1>
            <p className={"text-sm text-center text-gray-500 pb-6"}>Honest -
                Hard Working -
                Positive Attitude</p>
            <div className={"flex flex-row flex-wrap justify-center"}>
            <p className={"max-w-sm p-4"}>We are professionals we know
                what products work best on
                what surfaces. In addition to
                making everything look neat,
                tidy, and beautiful we are also
                disinfecting surfaces creating a
                safer healthier environment to
                live in. We systemically clean
                your house providing a
                consistent positive result each
                time we clean. </p>
            <p className={"max-w-sm p-4"}>We have been in business for
                over 10 years. Most of our
                clients stay with us until they
                move, we have had repeat
                business for years. We pride our
                selves on our professionalism,
                honesty, and attention to detail.
                We believe that with a positive
                attitude and strong work ethic
                we can get any job done. Rancho
                Bernardo Maids offers
                professional cleaning services for
                San Diego North County area
                including Rancho Bernardo,
                poway, and 4s ranch.</p>
            </div>
            <div className={"border border-malibu-500 mt-12 border-dashed"}></div>
        </div>
    )
}

export default MoreBusinessInfo